.selectProjBtn {
    background: none;
	color: inherit;
	border: none;
	padding: 10px 15px;
	margin: 0 15px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.selectProjBtn:hover, .selectProjBtn:focus{
    background-color: rgba(0, 0, 0, 0.541);
}

.projectListInteractionText {
    font-size: 1em;
    font-weight: bold;
}