.eye-catcher-main {
  position: relative;
}

.image-parent {
  display: grid;
  grid-template-columns: 1fr 4fr;
  position: relative;
  height: 300px;
}

.eye-catcher-image {
  background-image: url("./../../assets/eye-catcher.jpeg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.eye-catcher-title-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 650px;
  max-width: 75vw;
  height: 8px;
  background-color: var(--highlight-color);
}

.eye-catcher-title {
  display: inline-block;
  text-align: right;
  font-size: 32px;
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.eye-catcher-sub-title {
  position: absolute;
  right: 8px;
  font-size: 10px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

.eye-catcher-sub-title1 {
  top: 16px;
}

.eye-catcher-sub-title2 {
  top: 60px;
}

.eye-catchersub-title-icon {
  margin-right: 8px;
}

.overlay {
  background-color: black;
  opacity: 0.4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.heroIcon {
  position: absolute;
  left: 5%;
  bottom: -15px;
  width: 256px;
  max-width: 25%;
}

.baseline-mobile {
  height: 30px;
  background-color: var(--highlight-color);
}

@media(min-width: 500px) {
  .eye-catcher-title {
    font-size: 48px;
  }

  .eye-catcher-sub-title {
    font-size: 16px;
  }

  .eye-catcher-sub-title1 {
    top: 24px;
  }
  
  .eye-catcher-sub-title2 {
    top: 72px;
  }
}

@media(min-width: 901px) {
  .image-parent {
    height: calc(100vh - var(--header-height));
  }

  .heroIcon {
    bottom: -60px;
  }
}
