.contactForm {
    display: grid;
    grid-template-columns: auto;
    gap: 16px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}

input {
    height: 32px;
    display: block;
    background-color: var(--background-2nd);
    border: none;
    font-size: 16px;
    color: var(--text-color);
    padding: 2px 8px;
}

textarea {
    height: 250px;
    resize: none;
    background-color: var(--background-2nd);
    border: none;
    font-size: 16px;
    color: var(--text-color);
    padding: 8px;
}

.inputField {
    display: flex;
    gap: 16px;
    align-items: baseline;
    font-size: larger;
    color: var(--text-color);
}

button {
    display: block;
    background-color: var(--highlight-color);
    color: var(--text-color);
    padding: 8px;
    text-align: center;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: var(--highlight-color);
}

button:disabled {
    cursor: default;
}

.emailSent {
    background-color: var(--highlight-color);
    color: var(--text-color);
    padding: 8px;
    text-align: center;
    font-size: large;
    border-radius: 4px;
}

.vertical-divider {
    background-color: var(--highlight-color);
}

.contactTitle {
    display: block;
    color: var(--highlight-color);
    font-size: 48px;
}

.social-medias {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    padding-left: 50px;
    
}

@media (min-width: 900px) {
    .contactForm {
        grid-template-columns: 3fr 2px 2fr;
        gap: 64px;
    }

    .social-medias {
        padding: 16px;
        min-height: 250px;
    }

    form {
        padding: 8px;
    }

    .contactTitle {
        margin-bottom: 16px;
    }
}