.backToBlogLink {
    display: block;
    font-size: 2em;
    padding: 20px 10px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.markdown {
    line-height: 1.5;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.markdown p {
    font-size: 0.9em;
}

.markdown code {
    display: block;
    padding: 10px;
    color: rgb(119, 175, 55);
    background-color: rgb(32, 32, 32);
    font-size: 0.8em;
    overflow-x: auto;
}

.markdown table {
    display: inline-block;
    margin: 20px 0;
    width: 100%;
    border-collapse: collapse;
    border: 2px solid var(--highlight-color-2nd);
    font-size: 0.7em;
    overflow-x: auto;
}

.markdown td, .markdown th {
    padding: 8px;
    border-left: 2px solid var(--highlight-color-2nd);
}

.markdown td:last-child {
    width: 100%;
}

.markdown th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--highlight-color-2nd);
    color: white;
}

.markdown tr:nth-child(even){background-color: var(--dark-line);}
.markdown tr:nth-child(odd){background-color: var(--dark-line-2nd);}

.markdown a {
    color: white;
}

.markdown a:hover {
    color: var(--highlight-color-2nd);
}

.markdown a:focus, .markdown a:focus-visible {
    outline: var(--highlight-color-2nd) solid 3px;
}



.markdown h2, h3, h4, h5, h6 {
    color: var(--highlight-color);
    font-size: 1em;
    margin-top: 10px;
    margin-bottom: 5px;
}

.markdown h1:first-child {
    margin-top: 10px;
}

.markdown h1 {
    color: var(--highlight-color);
    border-bottom: 1px solid var(--highlight-color-2nd);
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.2em;
}

.markdown img {
    margin: 40px auto;
    display: block;
    min-width: 50%;
    max-width: 100%;
    max-height: 50vh;
    image-rendering: pixelated;
    align-self: right;
    background-color: var(--dark-line);
    padding: 5px;
    border-radius: 2.5px;
}

.markdown li {
    margin: 20px 0;
    margin-left: 30px;
}

.markdown em {
    display: block;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
}

.markdown blockquote {
    background: var(--highlight-color);
    border-left: 10px solid var(--highlight-color-2nd);
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}
.markdown   blockquote:before {
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}
.markdown  blockquote p {
    display: inline;
}

@media(min-width: 1500px) {
    .markdown {
        line-height: 1.5;
        max-width: 900px;
    }

    .markdown p {
        font-size: 1.2em;
    }

    .markdown code {
        font-size: 1.2em;
    }

    .markdown table {
        display: inline-table;
        margin: 20px 0;
        width: 100%;
        border-collapse: collapse;
        border: 2px solid var(--highlight-color-2nd);
        font-size: 0.8em;
    }

    .markdown h1 {
        margin-top: 50px;
        margin-bottom: 30px;
        font-size: 2em;
    }

    .markdown h2, h3, h4, h5, h6 {
        color: var(--highlight-color);
        font-size: 1.5em;
    }
}

