.section {
    margin: 64px 8px;
    padding: 0 32px;
    gap: 48px;
    max-width: 80vw;
}

.section h1{
    font-size: 48px;
    margin-bottom: 16px;
    color: var(--highlight-color);
}

.section p {
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 900px) {
    .section {
        margin: 64px 32px;
        margin-top: 128px;
        width: calc(100% - 98px);
        border-top: solid 8px var(--highlight-color);
    }

    .section h1{
        padding-top: 16px;
    }

    .section p{ 
        font-size: 24px;
        line-height: 40px;
    }
}
