.textAllignCenter {
    text-align: center;
}

.textAllignRight {
    text-align: right;
}

.textBold {
    font-weight: bold;
}
