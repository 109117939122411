.header {
    background-color: var(--highlight-color);
    height: var(--header-height);
}

.headerLink {
    font-size: 24px;
}

.menuIcon {
    position: fixed;
    top: 5px;
    left: 10px;
    background-color: var(--highlight-color);
    padding: 5px;
    border-radius: 5px;
    z-index: 1;
}

.menuIcon:hover {
   background-color: var(--highlight-color-2nd);
}

.dropdown {
    position: fixed;
    top: 70px;
    left: 10px;
    z-index: 10;
    background-color: var(--background);
    width: 250px;
    border: 2px solid var(--highlight-color);
    border-radius: 5px;
}

.dropdown-hidden {
    display: none;
}

.dropdown .navlink:hover {
    background-color: var(--background-2nd);
}
