/* Flex styles */
.flexAllignCenter {
    align-items: center;
}

.flexAllignBaseLine {
    align-items: baseline;
}

.flexJustifyCenter {
    justify-content: center;
}

.flexJustifySpaceBetween {
    justify-content: space-between;
}

.flexHorizontal {
    display: flex;
    flex-direction: row;
}

.flexVertical {
    display: flex;
    flex-direction: column;
}

.flexGap50 {
    gap: 50px;
}

.flexGap30 {
    gap: 30px;
}

.flexGap10 {
    gap: 10px;
}

.flexWrap {
    flex-wrap: wrap;
}

/* padding */

.padding10px {
    padding: 10px;
}

.padding20px {
    padding: 20px;
}

/* margin */

.marginY20px {
    margin: 20px 0;
}

.marginX20px {
    margin: 0 20px;
}

.ml10px {
    margin-left: 10px;
}

.mr32px {
    margin-right: 32px;
}

.mt10px {
    margin-top: 10px;
}

.mt20px {
    margin-top: 20px;
}

.mb10px {
    margin-bottom: 10px;
}

/* height */

.height30 {
    height: 30px;
}

.height50 {
    height: 50px;
}

.height80 {
    height: 80px;
}

.height40vh {
    height: 40vh;
}

.height60vh {
    height: 60vh;
}

.height70vh {
    height: 70vh;
}

.height80vh {
    height: 80vh;
}

.height20pr{
    height: 20%;
}

.height80pr{
    height: 80%;
}

.height100pr{
    height: 100%;
}

/* width */
.filler-horizontal {
    width: 100%;
}

.width100pr {
    width: 100%;
}

.width50pr {
    width: 50%;
}
