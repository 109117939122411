.game-card {
    width: 300px;
}

.game-card-container {
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: auto 150px 40px auto;
    grid-template-areas: 
    "thumbnail"
    "details"
    "itch-link"
    "play-in-web";
}

.thumbnailLink {
    grid-area: thumbnail;
    position: relative;
}

.thumbnail {
    min-width: 300px;
    max-width: 300px;
    align-self: center;
    display: block;
}

.gameInfo {
    display: none;
    background-color: var(--highlight-color);
    border-radius: 8px;
    border: solid var(--text-color) 2px;
    padding: 8px;
    position: absolute;
    top: 208px;
    left: -8px;
    width: 300px;
    font-size: small;
    text-decoration: none;
    color: var(--text-color);
    line-height: 20px;
}

.madeByText {
    margin-right: 4px;
    margin-top: 24px;
    line-height: 20px;
    width: 50px;
    font-weight: bold;
}

.thumbnailLink:hover .gameInfo, 
.thumbnailLink:focus .gameInfo {
    display:block
}

.mobilePlayBtn {
    background-color: var(--highlight-color);
    color: var(--text-color);
    padding: 8px;
    text-align: center;
    grid-area: play-in-web;
    text-decoration: none;
    height: 20px;
}

.details {
    grid-area: details;
}

.itchLink {
    grid-area: itch-link;
}

.game-properties {
    font-weight: bold;
    font-size: 1.2em;
}
