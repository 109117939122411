.topicContentContainer {
    height: 280px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.thumbnail {
    max-height: 200px;
    width: auto;
}

.topicContent {
    min-height: 128px;
    max-height: 128px;
}

.topicContentContainer h1 {
    font-size: 1.2em;
}

.topicContent h3 {
    margin-top: -4px;
    font-size: 1em;
    color: var(--highlight-color);
    text-decoration: underline;
}

.topicContent p {
    margin-top: 3px;
}


