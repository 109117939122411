.card {
    background-color: var(--background-2nd);
    border-radius: 5px;
}

.card-title {
    padding: 10px;
    height: 40px;
    font-size: 2em;
    font-weight: bold;
    background-color: var(--highlight-color);
    color: var(--text-color);
    text-align: center;
}

.nonLinkTitle {
    font-size: 1em;
    color: var(--text-color);
    text-align: center;
}
