.navlink {
    color: var(--text-color);
    text-decoration: none;
    font-size: 0.9em;
    font-weight: bold;
}

.navlink:focus-visible {
    outline: var(--highlight-color) solid 3px;
}

.link-active, .navlink:hover {
    color: var(--highlight-color-2nd);
}

@media (min-height: 400px) and (min-width: 400px) {
    .navlink {
        font-size: 1.2em;
    }
}