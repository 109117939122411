.ErrorHeader {
    font-size: 10vh;
}

.bringMeBackLink {
    text-decoration: none;
    color: var(--highlight-color);
    font-size: 1.2em;
}

.bringMeBackLink:hover {
    text-decoration: underline;
}