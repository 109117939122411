:root {
    --text-color: rgb(240, 235, 235);
    --background: rgb(12, 12, 14);
    --background-02: rgba(0, 0, 0, 0.2);
    --background-2nd: #141414;
    --dark-line: rgb(22, 22, 22);
    --dark-line-2nd: rgb(29, 29, 29);
    --background-body: rgb(23, 32, 30);
    --highlight-color: rgb(19, 93, 177);
    --highlight-color-2nd: rgb(83, 149, 224);
    --transparency: rgba(0,0,0,0);
}

.background-color-page {
    background-color: var(--background);
}

.backGroundHighlighted {
    color: white;
    background-color: var(--highlight-color);
}