.homeLayout {
    width: 90vw;
    background-color: var(--background);
    min-height: 100vh;
}

@media(min-width: 1300px) {
    .homeLayout {
        width: 80vw;
        min-width: 1150px;
    }
}