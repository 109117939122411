.defaultLayout {
    width: 90vw;
    background-color: var(--background);
    min-height: 100vh;
    position: relative;
}

.backgroundTransparency {
    background-color: var(--background-02);
}

.contentBackground {
    position: fixed;
    top: 0;
    background-image: url("./../../assets/eye-catcher.jpeg");
    background-position: bottom;
    background-repeat: repeat;
    background-size: cover;
    z-index: -1;
    height: 100vh;
    width: 90vw;
    filter: blur(2px);
    -webkit-filter: blur(2px);
    overflow-x: hidden;
}

.content {
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

@media(min-width: 1300px) {
    .defaultLayout {
        width: 80vw;
        min-width: 1150px;
    }

    .contentBackground {
        width: 80vw;
        min-width: 1150px;
    }
}