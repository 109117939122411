.linkOnBlack, .linkOnHighLight {
    text-decoration: none;
}

.linkOnBlack {
    color: var(--highlight-color-2nd);
    font-weight: bold;
}

.linkOnBlack:hover {
    color: var(--highlight-color);
}

.linkOnBlack:focus, .linkOnBlack:focus-visible {
    outline: var(--highlight-color) solid 3px;
}

.linkOnHighLight {
    color: white;
}

.linkOnHighLight:hover {
    color: var(--highlight-color-2nd);
}

.linkOnHighLight:focus, .linkOnHighLight:focus-visible {
    outline: var(--highlight-color-2nd) solid 3px;
}