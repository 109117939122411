.pill-button {
    all: unset;
    cursor: pointer;
    height: 30px;
    min-width: 50px;
    padding: 0 5px;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    border-color: white;
}

.pill-button:hover, .pill-button:focus {
    outline: var(--highlight-color-2nd) solid 3px;
}

.pill-button-active {
    background-color: var(--highlight-color-2nd);
}

.pill-button-disabled {
    background-color: rgba(255, 255, 255, 0.212);
    cursor: default;
}

.pill-button-disabled:hover {
    outline: none;
}