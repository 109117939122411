.blog-banner {
    max-height: 450px;
    overflow-y: auto;
}

.blog-banner h1{
    font-size: 1.2em;
    margin-bottom: 15px;    
}

.blog-banner-read-more-link {
    align-self: end;
    padding-top: 10px;
}

.thumbnail {
    display: inline-flex;
    max-height: 150px;
}

.draft {
    background-color: yellow;
    color: red;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
}
